import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { getAuctionsList, postAuctionsLotsApprove, postChangeAuctionTransition } from "@api/auctions";
import { Pagination } from "@components/pagination/pagination";
import { Table } from "@components/table/table";
import useMediaQuery from "@hooks/use-media-query";

import { getFinishedColumns } from "./utils";

import Filter from "@components/filters/Filter/Filter";
import FilterButton from "@components/filters/FilterButton/FilterButton";
import useSellFilter from "@hooks/filters/auctions/useSellFilter";
import { pusherStore } from "@mobx/pusher";
import { AuctionTradesHistoryModal } from "@pages/auction/auction-trades-history-modal/auction-trades-history-modal";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import { meStore } from "../../mobx/me";
import ToogleApproveVins from "../auction/ToogleApproveVins/ToogleApproveVins";
import "./sell.scss";
import { formatToPrural } from "@utils/filters/filter-constants";
import { DropdownButton } from "@components/dropdown/dropdown-button";

const PER_PAGE = [25, 50, 100, 250];

const PRURAL_FILTERS = [
	"filters[lots][sales][car][vins]",
	"filters[lots][sales][reg_numbers]"
];

export const Sell = observer(() => {

	const [searchParams, setSearchParams] = useSearchParams();
	const [isInitLoading, setIsInitLoading] = useState(false);

	const [showApproveVin, setShowApproveVin] = useState(false);

	const { ready, items, itemsAdditional, setDefaultItems, loadCarData } = useSellFilter();

	const [isReadyFilter, setIsReadyFilter] = useState(false);
	const [formData, setFormData] = useState({});
	const [filterShow, setFilterShow] = useState(false);

	const [lotId, setLotId] = useState();
	const [showTradesHistoryModal, setShowTradesHistoryModal] = useState(false);

	const [perPage, setPerPage] = useState(25);

	const [auctions, setAuctions] = useState();
	const isMobile = useMediaQuery("screen and (max-width: 780px)");

	const isBetViewExtend = meStore.hasPermission("bet.view.extend");

	const [auctionId, setAuctionId] = useState();
	const [auctionFinished, setAuctionFinished] = useState(false);

	const [lotMassIds, setLotMassIds] = useState([]);

	const auctionRequest = (params, filters = {}, activeTab) => {
		getAuctionsList({
			...params,
			...filters
		}, "auctions/selling").then(setAuctions);
	};

	const onAfterChangeStatusAuction = () => {
		auctionRequest({ page: auctions?.page, per_page: auctions?.per_page });
	};

	useEffect(() => {
		setIsReadyFilter(true);
	}, [ready]);

	const [updateHistory, setUpdateHistory] = useState(false);

	useEffect(() => {
		pusherStore.subscribeAuctions({
			onBetPlaced: () => {
				onSubmit();
				setUpdateHistory(prev => !prev);
			}
		});

		return () => {
			pusherStore.disconnect();
		};
	}, []);

	const handleShowLastBetDeleteModal = (data) => {
		setShowTradesHistoryModal(false);
	};

	const changeSelectFilter = async (key, type, data, reset) => {
		loadCarData(key, type, data, "filter:sell:data", reset);
	};

	const onInitSelectField = async (key, type, data) => {
		await changeSelectFilter(key, type, data);
	};

	const onChangeSelectField = async (key, type, data) => {
		await changeSelectFilter(key, type, data, true);
	};

	const onSubmit = (data) => {
		const _data = formatToPrural(data, PRURAL_FILTERS);
		setFormData(_data);
		setFilterShow(false);
		isInitLoading && setSearchParams({});
		auctionRequest({ page: !isInitLoading ? searchParams.get("page") : 1, per_page: perPage }, _data);
		setIsInitLoading(true);
	};

	const onPagination = ({ page, per_page }) => {
		setPerPage(per_page);
		setSearchParams({ page: page });
		auctionRequest({ page: page, per_page: per_page }, formData);
	};

	const onResetFilter = () => {
		setDefaultItems();
		setFilterShow(false);
	};

	const onCloseFilter = () => {
		setFilterShow(false);
	};

	const onSelectCheckboxesApprove = (ids) => {
		setLotMassIds(ids);
	};

	const elActions = () => {

		const onClickHandler = (approve) => {
			postAuctionsLotsApprove({ "ids": lotMassIds, approve: approve }).then(data => onAfterChangeStatusAuction());
		};
		const options = [
			{
				label: "Подтвердить",
				value: "approved",
				onClick: () => onClickHandler(true)
			},
			{
				label: "Отклонить",
				value: "canceled",
				onClick: () => onClickHandler(false)
			}
		];

		return <DropdownButton options={options} btnText="Действия" />;
	};

	return (
		<div className="buy-wrapper">
			{isReadyFilter &&
				<Filter
					data={items}
					additional={itemsAdditional}
					typeFilter={"filter:sell"}
					typeFilterData={"filter:sell:data"}
					onSubmit={onSubmit}
					indent
					mobileShow={filterShow}
					onInitSelectField={onInitSelectField}
					onChangeSelectField={onChangeSelectField}
					onReset={onResetFilter}
					onClose={onCloseFilter}
				/>
			}
			<div className="buy-wrapper__name-title">Продаю</div>
			<div className="title-wrapper">
				<div className="title">Аукционы</div>
				<div className="tabs d-flex align-items-center mt-0">
					{meStore.hasPermission("auctions.lots.mass.approve.by_vin") &&
						<>
							<div>
								<button
									onClick={() => setShowApproveVin(prev => !prev)}
									className={clsx("tab-btn", showApproveVin && "tab-btn-active")}
									type="button"
								>
									Пакетное подтверждение по VIN
								</button>
							</div>
						</>
					}
					{meStore.hasPermission("auctions.lots.mass.approve.list") &&
						<div>
							{elActions()}
						</div>
					}
				</div>
			</div>
			<FilterButton indent onClick={() => setFilterShow(true)} />

			{meStore.hasPermission("auctions.lots.mass.approve.by_vin") && showApproveVin &&
				<div className="sell-approve-vin">
					<ToogleApproveVins
						isClosing={true}
						onClose={() => {
							setShowApproveVin(false);
						}}
						onSuccess={() => {
							onAfterChangeStatusAuction();
							setShowApproveVin(false);
						}}
					/>
				</div>
			}
			{auctions && auctions.data?.length ? (
				<div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
					<Table
						data={auctions?.data}
						columns={getFinishedColumns(
							onAfterChangeStatusAuction,
							isBetViewExtend,
							setLotId,
							setShowTradesHistoryModal,
							setAuctionId,
							setAuctionFinished,
							meStore.hasPermission("auctions.lots.mass.approve.list"),
							auctions?.data,
							onSelectCheckboxesApprove
						)}
					/>
				</div>
			) : (
				"Лотов нет"
			)}
			<Pagination
				data={auctions}
				onChange={onPagination}
				perPageList={PER_PAGE}
				hideInfo={isMobile}
			/>
			{showTradesHistoryModal && (
				<AuctionTradesHistoryModal
					isFinishedAuction={auctionFinished}
					auctionId={auctionId}
					lotId={lotId}
					updateHistory={updateHistory}
					handleClose={() => setShowTradesHistoryModal(false)}
					handleShowLastBetDeleteModal={handleShowLastBetDeleteModal}
				/>
			)}
		</div>
	);
});
