import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { getXCSRFfToken, spaAuth } from '@api/auth'
import { postActivatePromo } from '@api/users'
import { Input } from '@components/input/input'
import { meStore } from '@mobx/me'
import { getLogoSrc, isKZ, ROOT_URL } from '@utils/common'

export const Login = () => {
	const {
		register,
		watch,
		formState: { errors, isValid },
		handleSubmit,
	} = useForm({ mode: 'onChange', defaultValues: { email: '', password: '' }, reValidateMode: 'onChange' })
	const values = watch()
	const [sending, setSending] = useState(false)
	const [valuesErrors, setValuesErrors] = useState()
	const [typeFieldPassword, setTypeFieldPassword] = useState('password')
	const [classFieldPassword, setClassFieldPassword] = useState('hide-password-input')
	const navigate = useNavigate()
	const { code } = useParams()

	const hidePassword = () => {
		if (typeFieldPassword === 'text') {
			setClassFieldPassword('hide-password-input')
			setTypeFieldPassword('password')
		} else {
			setClassFieldPassword('hide-password-input-active')
			setTypeFieldPassword('text')
		}
	}

	const onClassNameChange = (field, error) => {
		if (field === 'email') {
			if (values.email === '') {
				return ''
			}

			if (!values.email?.includes('@') || !values.email?.includes('.')) {
				return 'border-red'
			}
			if (values.email?.includes('@') && values.email?.includes('.')) {
				return 'border-green'
			}
		}

		if (values[field] !== '') {
			return 'border-green'
		}
		if (error) {
			return 'border-red'
		}
		return ''
	}

	const elInputStatus = (name, error) => {
		if (name === 'email') {
			if (values.email === '') {
				return ''
			} if (!values.email?.includes('@') || !values.email?.includes('.')) {
				return <span className="form-no-validate" />
			} if (values.email?.includes('@') && values.email?.includes('.')) {
				return <span className="form-validate" />
			}
		}

		if (values[name] !== '') {
			return <span className="form-validate" />
		} if (error) {
			return <span className="form-no-validate" />
		}
		return ''
	}

	const onFormSubmit = async (data) => {
		setSending(true)
		setValuesErrors(null)

		await getXCSRFfToken()

		spaAuth(data).then((response) => {
			if (response.message) {
				setValuesErrors(response.message)
				setSending(false)
			} else {
				meStore.setLogined()
				navigate(ROOT_URL)
				code && postActivatePromo(code)
			}
		})
	}

	return (
		<div className="login-wrapper">
			<img className="logo" src={getLogoSrc()} alt="logo" />
			<div className="form-lk-wrapper form-auth">
				<div className="title">Вход</div>
				{valuesErrors && <div className="alert alert-danger">Ошибка авторизации</div>}
				<form onSubmit={handleSubmit(onFormSubmit)}>
					<div className="mb-3 position-relative">
						<Input
							type="email"
							className={onClassNameChange('email', errors.email)}
							placeholder="Логин (E-mail)"
							{...register('email', {
								required: true,
								pattern: {
									value: /\S+@\S+\.\S+/,
								},
							})}
						/>
						{elInputStatus('email', errors.email)}
						<div>{errors?.email && <span className="error-message">Укажите корректный E-mail</span>}</div>
					</div>
					<div className="mb-3 position-relative">
						<Input
							type={typeFieldPassword}
							className={onClassNameChange('password', errors.password)}
							placeholder="Пароль"
							{...register('password', {
								required: true,
							})}
						/>
						<span onClick={hidePassword} className={classFieldPassword} />
						{elInputStatus('password', errors.password)}
						<div>{errors?.password && <span className="error-message">Укажите пароль</span>}</div>
					</div>
					<div className="mb-3">
						<button type="submit" className="btn btn-primary btn-submit" disabled={!isValid || sending}>
							Войти
						</button>
					</div>
					{isKZ &&
						<div className="mb-3">
							<Link to="/auth/signup" className="link-dark link-style">
								Регистрация
								<img className="arrow" src="/img/vector.svg" alt="vector" />
							</Link>
							<Link
								style={{ float: 'right' }}
								to="/auth/recovery"
								className="link-dark link-style"
							>
								Забыли пароль?
							</Link>
						</div>
					}
				</form>
			</div>
		</div>
	)
}
