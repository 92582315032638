import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { getCarsList } from "@api/cars";
import { getSaleModelsRequest } from "@api/dictionaries";
import { Button } from "@components/button/button";
import { InputRequisitesSearch } from "@components/input-requisites-search/input-requisites-search";
import { Input } from "@components/input/input";
import { Pagination } from "@components/pagination/pagination";
import { Select } from "@components/select/select";
import { Table } from "@components/table/table";
import { carBrandsStore } from "@mobx/car-brands";
import { auctionStore } from "@mobx/auction";

import { COLUMNS } from "./utils";

import "./cars-modal.scss";

const emptyFilters = {
	"filters[car][vin]": "",
	"filters[car][brand_ids][]": "",
	"filters[car][model_ids][]": "",
	"filters[reg_number]": "",
	"filters[company_ids][]": ""
};

export const CarsModal = ({
							  show,
							  onHide,
							  onChangeSales,
							  sales,
							  singleSelect,
							  isNewLot,
							  isUpAuction,
							  isEdit
						  }) => {
	const { brands } = carBrandsStore;
	const { auction } = auctionStore;
	const [cars, setCars] = useState();
	const [models, setModels] = useState([]);

	const [updateInput, setUpdateInput] = useState(0);

	const [selectedSales, setSelectedSales] = useState(sales || []);
	const [selectedRows, setSelectedRows] = useState(
		sales ? sales.map((item) => item.id) : []
	);
	const [filters, setFilters] = useState(emptyFilters);
	const [resetCompany, setResetCompany] = useState(0);

	useEffect(() => {
		console.log(auction);
	}, [auction]);

	const selectRow = {
		mode: "checkbox",
		clickToSelect: true,
		classes: "selection-row",
		selected: selectedRows,
		onSelectAll: (isSelect) => {
			if (singleSelect) {
				setSelectedSales([]);
				setSelectedRows([]);
			} else if (isSelect) {
				setSelectedSales(() => cars.data);
				setSelectedRows(() => cars.data.map((item) => item.id));
			} else {
				setSelectedSales([]);
				setSelectedRows([]);
			}
		},
		onSelect: (row, isSelect) => {
			if (singleSelect) {
				if (isSelect) {
					setSelectedSales([row]);
					setSelectedRows([row.id]);
				} else {
					setSelectedSales([]);
					setSelectedRows([]);
				}
			} else if (isSelect) {
				setSelectedSales((prev) => [...prev, row]);
				setSelectedRows((prev) => [...prev, row.id]);
			} else {
				setSelectedSales((prev) =>
					prev.filter((item) => item.id !== row.id)
				);
				setSelectedRows((prev) =>
					prev.filter((item) => item !== row.id)
				);
			}
		}
	};

	useEffect(() => {
		requestCars(1);
		if (!brands.length) carBrandsStore.fetch();
	}, []);

	const requestCars = (page, per_page) => {

		const defaultParams = auction.seller_type === "company"
			? { "filters[company_ids][]": auction.sellerable_id }
			: { "filters[owner_ids]": auction.sellerable_id };

		getCarsList({
			"filters[status_code]": "sale_ready_for_sale",
			"expand[11]": "statusReports",
			"expand[12]": "statusReports.responsible",
			"expand[13]": "statusReports.status",
			"expand[14]": "statusReports.updatedBy",
			"expand[15]": "statusReports.closedBy",
			...defaultParams,
			page,
			per_page
		}).then(setCars);
	};

	const saveSales = () => {
		onChangeSales(selectedSales);
		onHide();
	};

	const changePage = (index) => {
		if (index > 0) {
			requestCars(index);
		}
	};

	const pages = [];

	if (cars) {
		const count = Math.ceil(cars.total / cars.per_page);

		for (let i = 1; i <= count; i += 1) {
			pages.push(i);
		}
	}

	const filterSales = () => {
		const data = {};
		const newFilters = { ...filters };

		if (filters["filters[reg_number]"].length >= 3) {
			newFilters["filters[reg_number]"] = filters["filters[reg_number]"];
		} else {
			delete newFilters["filters[reg_number]"];
		}

		const newParams = Object.assign(newFilters, { "filters[status_code]": "sale_ready_for_sale" });
		Object.entries(newParams).forEach(([key, value]) => {
			if (value) data[key] = value;
		});
		getCarsList(data).then(setCars);
	};

	const clearAllFilters = () => {
		setUpdateInput(Math.random);
		setResetCompany(prev => prev + 1);
		requestCars(1);
		setFilters(emptyFilters);
	};

	const handleChangeFilters = (e) => {
		const newFilters = {
			...filters,
			[e.target.name]: e.target.value
		};

		if (e.target.name === "filters[car][brand_ids][]") {
			newFilters["filters[car][model_ids][]"] = "";
		}

		setFilters(newFilters);
	};

	const elFilters = () => {
		return (
			<div className="car-sales__filters-wrapper">
				<div className="filter">
					<div className="d-flex align-items-center flex-wrap gap-10px">
						<Select
							options={brands}
							valueId={filters["filters[car][brand_ids][]"]}
							size="xs"
							placeholder="Марка"
							onChange={({ value }) => {
								handleChangeFilters({
									target: { name: "filters[car][brand_ids][]", value }
								});
								getSaleModelsRequest(value, "car").then(
									setModels
								);
							}}
						/>
						<Select
							options={models?.map((model) => ({
								value: model.id,
								label: model.name
							}))}
							valueId={filters["filters[car][model_ids][]"]}
							size="xs"
							placeholder="Модель"
							onChange={({ value }) =>
								handleChangeFilters({
									target: { name: "filters[car][model_ids][]", value }
								})
							}
						/>
						<Input
							placeholder="VIN"
							name="filters[car][vin]"
							value={filters["filters[car][vin]"]}
							onChange={handleChangeFilters}
							className="input_small"
							size="xs"
						/>
						<Input
							placeholder="Гос. номер"
							name="filters[reg_number]"
							value={filters["filters[reg_number]"]}
							onChange={handleChangeFilters}
							className="input_small"
							size="xs"
						/>
						<InputRequisitesSearch
							placeholder="Продавец"
							size="xs"
							resetValue={resetCompany}
							defaultValue={auction.seller_title}
							update={updateInput}
							onChange={(value, type) => {
								console.log(type);
								handleChangeFilters({
									target: {
										name: type === "department" ? "filters[company_ids][]" : "filters[owner_ids]",
										value: [value]
									}
								});
							}}
						/>
					</div>
					<div className="car-sales__filters-buttons">
						<Button onClick={filterSales} size="s">
							Применить фильтры
						</Button>
						<Button
							onClick={clearAllFilters}
							size="s"
							preset="secondary"
							className="d-flex align-items-center gap-5px"
						>
							<img src="/img/icons/filters-off-icon.svg" />
							Очистить
						</Button>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="car-modal-wrapper">
			<Modal show={show} onHide={onHide} className="content-modal-car">
				<div className="d-flex align-items-start header-car">
					<div className="title">
						Выберите автомобили для добавления в лот
					</div>
				</div>
				{elFilters()}
				<div className="car-table">
					{cars?.data?.length ? (
						<Table
							data={cars?.data}
							selectRow={selectRow}
							columns={COLUMNS}
						/>
					) : (
						<p>Доступных машин нет</p>
					)}
					<Pagination
						data={cars}
						onChangePage={changePage}
						onChangePerPage={(e) => requestCars(1, e.target.value)}
						perPageList={[25, 50, 100, 250, 500]}
					/>
					<Button onClick={saveSales} preset="primary">
						Добавить
					</Button>
				</div>
			</Modal>
		</div>
	);
};
