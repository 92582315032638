import { uuid } from '@utils/uuid'
import { useEffect, useState } from 'react'
import { PermissionsGroup, PermissionsObject } from '../../../types/Permissions'
import Container from '../../Containers/Container/Container'
import Badge from '../../utils/Barge/Badge'
import styles from './PermissionList.module.scss'

type PermissionListProps = {
	permissions: PermissionsObject,
	selectedPermissions?: []
}

type Object = {
	[key: string]: any
}

const PermissionList = ({ permissions, selectedPermissions }: PermissionListProps) => {

	const [group, setGroup] = useState<PermissionsGroup[]>()
	const setSortGroup = (data: PermissionsObject) => {
		const array = Object.values(data).map(x => x)

		const uniqueKeys = [...new Set(array.map(x => x.group))]

		return uniqueKeys.map(key => ({
			id: uuid(),
			name: key,
			data: array.filter(item => item.group === key),
		}))
	}

	const getCurrentPermissions = (): PermissionsObject => {

		let object: Object = {}
		selectedPermissions?.forEach((x: number, index) => {
			object[x] = permissions[x]
		})

		return object
	}

	useEffect(() => {
		const getCurrentPermissionsObject = getCurrentPermissions()
		const getSortingPermissionsArray = setSortGroup(getCurrentPermissionsObject)
		setGroup(getSortingPermissionsArray)
	}, [selectedPermissions])

	return (
		<Container>
			{group?.map(x =>
				<div key={x.id} className={styles.item}>
					<div className={styles.title}>
						{x.name}
					</div>
					<div className={styles.section}>
						{x.data.map(x =>
							<Badge key={x.id} text={x.description} name={x.name} />
						)}
					</div>
				</div>
			)}
		</Container>
	)
}

export default PermissionList
