import clsx from "clsx";
import styles from "./Checkbox.module.scss";

type CheckboxProps = {
	labelBefore?: string;
	label?: string;
	type?: "checkbox" | "radio";
	value?: any;
	name?: string;
	defaultChecked?: boolean;
	required?: boolean;
};

const Checkbox = ({
	                  labelBefore,
	                  label,
	                  type = "checkbox",
	                  name,
	                  value,
	                  defaultChecked,
	                  required,
	                  ...args
                  }: CheckboxProps) => {
	return (
		<label
			className={clsx(
				styles.Checkbox,
				label && styles.label,
				labelBefore && styles.labelBefore,
				type === "radio" && styles.radio
			)}
		>
			{labelBefore}
			<input
				name={name}
				type={type}
				value={value}
				defaultChecked={defaultChecked}
				required={required}
				{...args}
			/>
			<span></span>
			{label}
		</label>
	);
};

export default Checkbox;
