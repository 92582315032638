import { postAuctionsLotsApprove } from '@api/auctions'
import clsx from 'clsx'
import { useState } from 'react'
import { Modal } from "react-bootstrap"
import Btn from '../../../components/btn/Btn/Btn'
import TextArea from '../../../components/form/TextArea/TextArea'
import styles from "./ToogleApproveVins.module.scss"

interface ToogleApproveVinsProps {
	className?: string
	auction: any
	isClosing?: boolean
	onSuccess(): void
	onClose?(): void
}

const ToogleApproveVins = (props: ToogleApproveVinsProps) => {
	const { className, auction, isClosing, onSuccess, onClose } = props
	const [show, setShow] = useState(false)
	const [title, setTitle] = useState('')
	const [approve, setApprove] = useState(false)
	const [vins, setVins] = useState<string[]>([])

	const handleClose = () => {
		setShow(false)
	}

	const onApprove = (t: boolean) => {
		setTitle(t ? 'Вы точно хотите подвердить?' : 'Вы точно хотите отклонить?')
		setShow(true)
		setApprove(t)
	}

	const onInput = (value: string) => {
		setVins(value.split(/,|\.|\;|\:|\-|\s/gm).filter(x => x.trim()))
	}

	const onSubmit = () => {

		postAuctionsLotsApprove({ 'vin': vins, auction_id: auction?.id, approve: approve }).then(data => {
			setShow(false)
			onSuccess()
		})
	}

	return (
		<>
			<div className={clsx(styles.ToogleApproveVins)}>
				{isClosing &&
					<div className={styles.close} onClick={onClose}>
						<img src="/img/icons/remove-icon.svg" alt="" />
					</div>
				}
				<div className={styles.title}>Для разделения VIN номеров нужно использовать следующие символы: <code>, . ; : - или пробел </code></div>
				<TextArea name={''} onInput={onInput} className={styles.textarea}></TextArea>
				<div className={styles.buttons}>
					<Btn onClick={() => onApprove(true)}>Пакетное подтверждение по VIN</Btn>
					<Btn onClick={() => onApprove(false)} color={'redBorder'}>Пакетная отмена по VIN</Btn>
				</div>
			</div >
			<Modal
				show={show}
				onHide={handleClose}
				className="auto-bet-modal-wrapper"
			>
				<div className="title">{title}</div>
				<div className={styles.buttonsModal}>
					<Btn onClick={onSubmit}>Да</Btn>
					<Btn onClick={handleClose} color={'white'}>Нет</Btn>
				</div>
			</Modal>
		</>
	)
}

export default ToogleApproveVins