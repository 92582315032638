import { getAuctionsTypesRequest } from "@api/auctions";
import { getDictionaries } from "@api/dictionaries";
import { useEffect, useState } from "react";
import { citiesStore } from "../../../mobx/cities";
import { dictionariesStore } from "../../../mobx/dictionaries";

const REQUEST_URLS = [
	{
		type: "status",
		filters: { filters: { entity: "sale" }, saveKey: "car_status" }
	},
	{
		type: "status",
		filters: { filters: { entity: "auction" }, saveKey: "auction_status" }
	},
	{
		type: "car_locations"
	},
	{
		type: "areas"
	},
	{
		type: "inspection_types"
	},
	{
		type: "car_colors"
	},
	{
		type: "car_drives"
	},
	{
		type: "car_transmissions"
	}
];

const FILTER_ITEMS = {
	"filters[lots][sales][status_id]": "car_status",
	"filters[status_ids][]": "auction_status",
	"filters[lots][sales][location_id]": "car_locations"
};

const FILTER_ITEMS_ADD = {
	"filters[lots][sales][area_ids][]": "areas",
	"filters[lots][sales][inspection]": "inspection_types",
	"filters[lots][sales][color_ids][]": "car_colors",
	"filters[lots][sales][car][drive_id]": "car_drives",
	"filters[lots][sales][car][transmission_id]": "car_transmissions"
};

const FORM_RESET_TYPES = [
	"filters[lots][sales][car][brand_ids][]",
	"filters[lots][sales][car][model_ids][]",
	"filters[lots][sales][car][engine_id]",
	"filters[lots][sales][car][body_id]"
];

const FORM_TYPES_LOAD = {
	"filters[lots][sales][car][brand_ids][]": "car_brands",
	"filters[lots][sales][car][engine_id]": "car_fuels",
	"filters[lots][sales][car][body_id]": "car_bodies"
};

const useTradesFilter = () => {
	const [ready, setReady] = useState(false);
	const [items, setItems] = useState([
		{
			id: 0,
			type: "select",
			name: "filters[lots][sales][car][type_id]",
			placeholder: "Категория Т/С",
			options: [],
			value: [],
			className: "red"
		},
		{
			id: 1,
			type: "select",
			name: "filters[lots][sales][car][brand_ids][]",
			placeholder: "Марка",
			options: [],
			value: [],
			disabled: true
		},
		{
			id: 2,
			type: "select",
			name: "filters[lots][sales][car][model_ids][]",
			placeholder: "Модель",
			options: [],
			value: [],
			reset: false,
			disabled: true
		},
		{
			id: 3,
			type: "select",
			name: "filters[lots][sales][car][body_id]",
			placeholder: "Тип кузова",
			options: [],
			value: [],
			disabled: true
		},
		{
			id: 4,
			type: "select",
			name: "filters[lots][sales][car][engine_id]",
			placeholder: "Тип двигателя",
			options: [],
			value: [],
			disabled: true
		},
		{
			id: 5,
			type: "text",
			name: "filters[ids][]",
			placeholder: "Номер аукциона"
		},
		{
			id: 6,
			type: "select",
			name: "filters[auction_type]",
			placeholder: "Тип аукциона",
			options: [],
			value: []
		},
		{
			id: 7,
			type: "date",
			name: "filters[start_at_gt]",
			placeholder: "Даты проведения от"
		},
		{
			id: 8,
			type: "date",
			name: "filters[start_at_ls]",
			placeholder: "Даты проведения до"
		},
		{
			id: 9,
			type: "text",
			name: "filters[lots][sales][car][vins]",
			placeholder: "VIN"
		},
		{
			id: 10,
			type: "number",
			name: "filters[lots][sales][car][year_gt]",
			placeholder: "Год от",
			min: 1900,
			max: new Date().getFullYear()
		},
		{
			id: 11,
			type: "number",
			name: "filters[lots][sales][car][year_ls]",
			placeholder: "Год до",
			min: 1900,
			max: new Date().getFullYear()
		},
		{
			id: 12,
			type: "number",
			name: "filters[lots][sales][mileage_gt]",
			placeholder: "Пробег от"
		},
		{
			id: 13,
			type: "number",
			name: "filters[lots][sales][mileage_ls]",
			placeholder: "Пробег до"
		},
		{
			id: 14,
			type: "select",
			name: "filters[lots][sales][city_ids][]",
			isMulti: true,
			placeholder: "Город",
			options: [],
			value: []
		},
		{
			id: 15,
			type: "search",
			name: "filters[company_ids][]",
			placeholder: "Компании",
			typeId: 2
		},
		{
			id: 16,
			type: "select",
			name: "filters[lots][sales][location_id]",
			placeholder: "Местонахождение",
			options: [],
			value: []
		}
	]);

	const [itemsAdditional, setItemsAdditional] = useState([
		{
			id: 16,
			type: "select",
			name: "filters[lots][sales][car][transmission_id]",
			placeholder: "КПП",
			options: [],
			value: []
		},
		{
			id: 17,
			type: "select",
			name: "filters[lots][sales][car][drive_id]",
			placeholder: "Привод",
			options: [],
			value: []
		},
		{
			id: 18,
			type: "number",
			name: "filters[lots][sales][car][engine_volume_gt]",
			placeholder: "Объем от"
		},
		{
			id: 19,
			type: "number",
			name: "filters[lots][sales][car][engine_volume_ls]",
			placeholder: "Объем до"
		},
		{
			id: 20,
			type: "select",
			name: "filters[lots][sales][color_ids][]",
			placeholder: "Цвет",
			isMulti: true,
			options: [],
			value: []
		},
		{
			id: 21,
			type: "select",
			name: "filters[lots][sales][inspection]",
			placeholder: "Осмотрено",
			options: [],
			value: []
		},
		{
			id: 22,
			type: "select",
			name: "filters[lots][sales][area_ids][]",
			isMulti: true,
			placeholder: "Регион",
			options: [],
			value: []
		}
	]);

	const [carType, setCarType] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			let carTypes = await getDictionaries("car_types");
			const auctionTypes = await getAuctionsTypesRequest();
			await citiesStore.fetch();

			for (const x of REQUEST_URLS) {
				await dictionariesStore.request(x.type, x.filters);
			}

			setItems(
				items.map((x) => {
					if (x.name === "filters[lots][sales][city_ids][]")
						x.options = citiesStore.cities.map((x) => ({
							value: x.id,
							label: x.name
						}));
					if (x.name === "filters[lots][sales][car][type_id]")
						x.options = carTypes.map((x) => ({
							value: x.id,
							label: x.name,
							type: x.key
						}));
					if (x.name === "filters[auction_type]")
						x.options = auctionTypes.map((x) => ({
							value: x.key,
							label: x.title
						}));
					if (FILTER_ITEMS.hasOwnProperty(x.name)) {
						x.options =
							dictionariesStore.dictionaries[
								FILTER_ITEMS[x.name]
								];
					}
					return x;
				})
			);

			if (!citiesStore.cities?.length) await citiesStore.fetch();

			setItemsAdditional(
				itemsAdditional.map((x) => {
					if (x.name === "filters[lots][sales][city_ids][]")
						x.options = citiesStore.cities.map((x) => ({
							value: x.id,
							label: x.name
						}));
					if (FILTER_ITEMS_ADD.hasOwnProperty(x.name)) {
						x.options =
							dictionariesStore.dictionaries[
								FILTER_ITEMS_ADD[x.name]
								];
					}
					return x;
				})
			);
		};

		fetchData().then((_) => setReady(true));
	}, []);

	const setDefaultItems = () => {
		setItems(
			items.map((x) => {
				if (FORM_RESET_TYPES.includes(x.name)) {
					x.options = [];
					x.disabled = true;
				}
				return x;
			})
		);
	};

	const loadCarData = async (key, type, data, itemStorage, reset) => {
		let array = items.map((x) => x);

		if (type === "select") {
			if (
				key === "filters[lots][sales][car][type_id]" &&
				!data[0]?.value
			) {
				array = array.map((x) => {
					if (FORM_RESET_TYPES.includes(x.name)) {
						x.options = [];
						x.disabled = true;
						x.reset = !x.reset;
					}
					return x;
				});
			}

			if (
				key === "filters[lots][sales][car][brand_ids][]" &&
				!data[0]?.value
			) {
				array = array.map((x) => {
					if (x.name === "filters[lots][sales][car][model_ids][]") {
						x.options = [];
						x.disabled = true;
						x.reset = !x.reset;
					}
					return x;
				});
			}

			if (
				key === "filters[lots][sales][car][type_id]" &&
				data[0]?.value
			) {
				let type = data[0].type;
				setCarType(type);
				for (let key in FORM_TYPES_LOAD) {
					await dictionariesStore.request(FORM_TYPES_LOAD[key], {
						filters: { car_type_key: type }
					});
				}

				array = array.map((x) => {
					if (FORM_TYPES_LOAD.hasOwnProperty(x.name)) {
						x.options =
							dictionariesStore.dictionaries[
								FORM_TYPES_LOAD[x.name]
								];
						x.disabled = false;
						if (reset) x.reset = !x.reset;
					}
					return x;
				});
			}
			if (
				key === "filters[lots][sales][car][brand_ids][]" &&
				data[0]?.value
			) {
				let parse = JSON.parse(sessionStorage.getItem(itemStorage));
				let type = reset
					? carType
					: parse["filters[lots][sales][car][type_id]"][0].type;
				await dictionariesStore.request("car_models", {
					filters: {
						car_brand_id: data[0]?.value,
						car_type_key: type
					}
				});

				array = array.map((x) => {
					if (x.name === "filters[lots][sales][car][model_ids][]") {
						x.options = dictionariesStore.dictionaries?.car_models;
						x.disabled = false;
						if (reset) x.reset = !x.reset;
					}
					return x;
				});
			}
		}

		setItems(array);
	};

	return {
		ready,
		items,
		setItems,
		itemsAdditional,
		setItemsAdditional,
		setDefaultItems,
		loadCarData
	};
};

export default useTradesFilter;
