import { useEffect, useRef, useState, lazy } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import "bootstrap/dist/css/bootstrap.min.css"
import "./styles/modal.scss"

import { LayoutAuth, LayoutMain, LayoutPageNotFound } from "@components/layout/layout"
import ProtectedRoute from "@components/protected-route/protected-route"
import { AuctionForm } from "@pages/auction-form/auction-form"
import { Auction } from "@pages/auction/auction"
import { AuctionsCalendar } from "@pages/auctions-calendar/auctions-calendar"
import { Auctions } from "@pages/auctions/auctions"
import { Login } from "@pages/auth/login"
import { Out } from "@pages/auth/out"
import { Recovery } from "@pages/auth/recovery"
import { ResetConfirm } from "@pages/auth/reset-confirm"
import { Buy } from "@pages/buy/buy"
import { CarForm } from "@pages/car-form/car-form"
import { Car } from "@pages/car/car"
import { Cars } from "@pages/cars/cars"
import { ChangePassword } from "@pages/change-password/change-password"
import { CodeAuth } from "@pages/code-auth/code-auth"
import { CompanyForm } from "@pages/company-form/company-form"
import { Company } from "@pages/company/company"
import { CurrentTrades } from "@pages/current-trades/current-trades"
import { DepartmentForm } from "@pages/department-form/department-form"
import { Department } from "@pages/department/department"
import { ExchangeOffer } from "@pages/exchange-offer/exchange-offer"
import { AddLot } from "@pages/exchange/add-lot"
import { Exchange } from "@pages/exchange/exchange"
import { FavouriteLots } from "@pages/favourite-lots/favourite-lots"
import { NotFoundPage } from "@pages/not-found-page"
import { ReportMake } from "@pages/report-make/report-make"
import { Report } from "@pages/report/report"
import AddRole from "@pages/role/AddRole/AddRole"
import AssignRole from "@pages/role/AssignRole/AssignRole"
import { Root } from "@pages/root/root"
import { Sell } from "@pages/sell/sell"
import { TemporaryRegister } from "@pages/temporary-register/temporary-register"
import { UserForm } from "@pages/user-form/user-form"
import { UserGroupForm } from "@pages/user-group-form/user-group-form"
import { UserGroup } from "@pages/user-group/user-group"
import { UserProfile } from "@pages/user-profile/user-profile"
import { User } from "@pages/user/user"
import { Users } from "@pages/users/users"
import { isMobile } from "mobile-device-detect"

import { isKZ } from "@utils/common"

import ModalInfo from "@components/utils/ModalInfo/ModalInfo"
import CompaniesPage from "@pages/companies/companies-page"
import "./main.scss"
import ArvalAuctionForm from './pages/arval/arval-auction-form/ArvalAuctionForm'
import { Signup } from './pages/auth/signup'

function App() {

	const [isShowModal, setIsShowModal] = useState(false)
	const hideDate = useRef(new Date().getTime())
	const timeOut = useRef(0)

	const updateVersion = () => {

		if (document.visibilityState !== "visible") {
			hideDate.current = new Date().getTime()
		}

		let diff = new Date().getTime() - hideDate.current
		if (diff > timeOut.current) {
			if (!["/auth/recovery", "/auth/signup", "/auth/login"].includes(window.location.pathname)) {
				setIsShowModal(true)
			} else {
				console.log(window.location.pathname)
			}
		}
	}

	useEffect(() => {

		timeOut.current = isMobile ? 1000 * 60 * 10 : 1000 * 60 * 30
		document.title = isKZ ? "AuctionCar" : "Автомарт"

		const link = document.createElement("link")
		link.rel = "shortcut icon"
		link.href = isKZ ? "/favicon_kz.ico" : "/favicon.ico"
		document.head.appendChild(link)


		document.addEventListener("visibilitychange", () => {
			updateVersion()
		})

	}, [])

	const onReload = () => {
		window.location.reload()
	}

	return (
		<>
			<Routes>
				<Route
					path="/"
					element={<Root />}
				/>
				<Route
					path="/auth/login"
					element={(
						<Login />
					)}
				/>
				<Route
					path="/auth/login/:code?"
					element={(
						<Login />
					)}
				/>
				<Route
					path="/auth/signup"
					element={(
						<LayoutAuth>
							<Signup />
						</LayoutAuth>
					)}
				/>
				<Route
					path="/auth/simple-register/:code"
					element={(
						<LayoutAuth>
							<TemporaryRegister />
						</LayoutAuth>
					)}
				/>
				<Route
					path="/auth/code/:code"
					element={(
						<CodeAuth />
					)}
				/>
				<Route
					path="/auth/recovery"
					element={(
						<LayoutAuth>
							<Recovery />
						</LayoutAuth>
					)}
				/>
				<Route
					path="/user/reset"
					element={(
						<LayoutAuth>
							<ResetConfirm />
						</LayoutAuth>
					)}
				/>
				<Route
					path="/users"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Users} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/user/:id"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={User} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/user/:id/assign-role"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={AssignRole} props={{ isUser: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/users/create"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={UserForm} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/users/:id/edit"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={UserForm} props={{ isEdit: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/users/roles/add"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={AddRole} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/users/roles/edit/:id"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={AddRole} props={{ isEdit: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/profile/change-password"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={ChangePassword} props={{ isProfile: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/users/:id/change-password"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={ChangePassword} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/current-trades"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={CurrentTrades} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/exchange"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Exchange} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/exchange-offer/add"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={AddLot} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/exchange-offer/:id/edit"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={AddLot} props={{ isEdit: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/exchange-offer/:id"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={ExchangeOffer} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/car/:id"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Car} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/car/:id/edit"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={CarForm} props={{ edit: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/car/create"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={CarForm} props={{ isNew: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/auction/:id"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Auction} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/auction/:id/edit"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={AuctionForm} props={{ edit: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/add-auction"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={AuctionForm} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/arval/add-auction"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={ArvalAuctionForm} props={{
								disabledFields: {
									expires_at: true,
									sellerable: true
								},
								typeManager: 'arval_xml',
								auctionType: 'parallel_increase'
							}} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/auctions"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Auctions} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/auction-calendar"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={AuctionsCalendar} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/companies"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={CompaniesPage} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/companies/roles/add"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={AddRole} props={{ url: "/companies" }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/companies/roles/edit/:id"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={AddRole} props={{ isEdit: true, url: "/companies" }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/create-company"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={CompanyForm} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/company/:id"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Company} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/company/:id/edit"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={CompanyForm} props={{ isEdit: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/company/:id/assign-role"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={AssignRole} props={{ isCompany: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/user-group/create"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={UserGroupForm} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/user-group/:id/edit"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={UserGroupForm} props={{ isEdit: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/user-group/:id"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={UserGroup} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/company/:id/add-department"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={DepartmentForm} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/profile"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={UserProfile} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/buy"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Buy} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/sell"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Sell} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/sell/auction/:id"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Auction} props={{ isSell: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/car/:saleId/report/:reportId"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Report} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/favourite-lots"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={FavouriteLots} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/cars"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Cars} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/department/:id"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={Department} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/department/:id/edit"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={DepartmentForm} props={{ isEdit: true }} />
						</LayoutMain>
					)}
				/>
				<Route
					path="/reporting"
					element={(
						<LayoutMain>
							<ProtectedRoute Component={ReportMake} />
						</LayoutMain>
					)}
				/>
				<Route
					path="auth/registration-confirm"
					element={<Navigate to="/profile" replace />}
				/>
				<Route path="/user/out" element={<Out />} />
				<Route
					path="*"
					element={(
						<LayoutPageNotFound>
							<NotFoundPage />
						</LayoutPageNotFound>
					)}
				/>
			</Routes>
			<ModalInfo
				show={isShowModal}
				title={"Информация могла устареть, необходимо обновить страницу!"}
				onClick={onReload}
			/>
		</>
	)
}

export default App
