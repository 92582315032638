import clsx from "clsx";
import { useEffect, useState } from "react";

import { getRequisitesSearch } from "@api/auctions";
import { IconSearch } from "@components/icons/seach";
import { ChooseSellerModal } from "@components/input-requisites-search/choose-seller-modal";
import { Input } from "@components/input/input";
import { Suggestions } from "@components/suggestions/suggestions";

import "./input-requisites-search.scss";

const TEMPLATES = {
	legal: {
		key: "company",
		type: "department"
	},
	personal: {
		key: "user",
		type: "user"
	}
};

export const InputRequisitesSearch = (props) => {
	const [inputValue, setInputValue] = useState(props.defaultValue || "");
	const [options, setOptions] = useState([]);
	const [isShowChooseSellerModal, setIsShowChooseSellerModal] = useState(false);

	const onSelectSeller = (row, type) => {
		if (row) {
			setInputValue(() => (
				type === "user" ? row?.full_name : row?.display_name
			));
			props.onChange(row.id, type);
			setIsShowChooseSellerModal(false);
		}
	};

	useEffect(() => {
		if (props.resetValue) setInputValue("");
	}, [props.resetValue]);

	useEffect(() => {
		setInputValue(props.defaultValue);
	}, [props.update]);

	const onInputChange = (e) => {
		const { value } = e.target;

		setInputValue(value);
		if (value.length >= 3) {
			getRequisitesSearch({ type_id: props.typeId ?? "2", name: value })
				.then((resp) => {
					const options = [];

					resp.data.forEach((item) => {
						let obj = TEMPLATES[item.template];
						options.push({ label: item[obj.key].display_name, value: item[obj.key].id, type: obj.type });
					});

					setOptions(options);
				});
		}
	};

	return (
		<>
			<Suggestions
				value={inputValue}
				items={options}
				className={props.size}
				onSelectItem={(item) => {
					setInputValue(item.label);
					props.onChange(item.value, item.type);
					setOptions([]);
				}}
			>
				<div className="position-relative">
					{props.isChooseSeller
						&& (
							<span onClick={() => setIsShowChooseSellerModal(true)}>
								<IconSearch
									className="input-requisites-search__icon"
								/>
							</span>
						)}
					<Input
						name={props?.name}
						onChange={props.disabled ? undefined : onInputChange}
						value={inputValue}
						className={clsx(props.className, { "input-requisites-search__with-icon": props.isChooseSeller })}
						placeholder={props.placeholder}
						autoComplete="off"
						disabled={props.disabled}
						size={props.size}
						onBlur={() => {
							setTimeout(() => {
								setOptions([]);
							}, 200);
						}}
					/>
				</div>
			</Suggestions>
			{isShowChooseSellerModal && (
				<ChooseSellerModal
					handleClose={() => setIsShowChooseSellerModal(false)}
					onSelectSeller={onSelectSeller}
				/>
			)}
		</>
	);
};
