import classNames from "classnames"
import styles from "./Icon.module.scss"
import { IconType } from "./IconType"

const IconPreloader = ({ className, size = 16 }: IconType) => {
	return (
		<svg
			className={classNames(className, styles[`Icon${size}`])}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
			fill="none"
		>
			<g stroke="currentColor">
				<circle strokeDasharray="164.93361431346415 56.97787143782138" r="35" strokeWidth="5"
					fill="none" cy="50" cx="50">
					<animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1s" repeatCount="indefinite"
						type="rotate" attributeName="transform"></animateTransform>
				</circle>
			</g>
		</svg>
	)
}

export default IconPreloader
