import React from 'react'
import styles from './Badge.module.scss'
import clsx from 'clsx'

type BadgeProps = {
	text: string
	className?: string
	color?: 'gray'
	name?: string
}

const Badge = ({ text, className, color = 'gray', name }: BadgeProps) => {
	return (
		<div className={clsx(className, styles.Badge, styles[color])} data-name-role={name}>
			{text}
		</div>
	)
}

export default Badge
