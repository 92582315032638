import moment from "moment";
import { Link } from "react-router-dom";

import { Tooltip } from "@components/tooltip/tooltip";
import {
	getTypeAuctionText,
	LOT_GREEN_STATUSES,
	LOT_GREY_STATUSES,
	LOT_ORANGE_STATUSES,
	LOT_RED_STATUSES
} from "@utils/common";
import { formatDateWithTime } from "@utils/date";
import { formatValueWithSpaces } from "@utils/format";

export const getActiveColumns = [
	{
		dataField: "id",
		text: "№",
		formatter: (cell, row) => (
			<div className="min-height">
				<div>{row?.id}</div>
				<div className="table__file-extra-text">
					{getTypeAuctionText(row?.auction_type)}
				</div>
			</div>
		)
	},
	{
		dataField: "start_at",
		text: "Даты",
		headerStyle: () => {
			return { width: "100px" };
		},
		formatter: (cell, row) => (
			<div className="d-flex flex-column min-height gap-5px justify-content-end align-items-end">
				{row.start_at && (
					<div>
						<div>{moment(row.start_at).format("DD.MM.YYYY")}</div>
						<div className="table__file-extra-text">
							{moment(row.start_at).format("HH:mm")}
						</div>
					</div>
				)}
				{row?.planned_finish_at && (
					<div className="d-flex">
						–
						<div>
							<div>
								{moment(row.planned_finish_at).format(
									"DD.MM.YYYY"
								)}
							</div>
							<div className="table__file-extra-text">
								{moment(row.planned_finish_at).format("HH:mm")}
							</div>
						</div>
					</div>
				)}
			</div>
		)
	},
	{
		dataField: "seller_title",
		text: "Продавец",
		headerStyle: () => {
			return { width: "200px" };
		},
		formatter: (cell, row) => (
			<div className="min-height">{row.seller_title}</div>
		)
	},
	{
		dataField: "lot",
		text: "Лоты",
		headerStyle: () => {
			return { width: "350px" };
		},
		formatter: (cell, row) => {
			return (
				<div className="d-grid gap-5px">
					{row.lots.map((lot, index) => {
						let relatedStatusReports = lot.relatedStatusReport;
						return (
							<div
								key={`lot-${index}`}
								className="d-grid gap-5px"
							>
								{lot.sales?.map((sale, index) => {
									let currentStatusReport = relatedStatusReports.find(x => x.sale_id === sale.id);
									let urlStatusReport = currentStatusReport?.status_report?.id ? `&statusReportId=${currentStatusReport?.status_report?.id}` : "";
									if (!currentStatusReport?.status_report) {
										currentStatusReport = {
											status_report: {
												reg_number: sale.status_reports[0]?.reg_number
											}
										};
									}
									return (
										<Link
											to={`/car/${sale.id}?auctionId=${lot.auction_id}&lotId=${lot.id}&lotSale=true${urlStatusReport}`}
											key={`lot-sale-${index}`}
											className="table__file-blue-text min-height"
										>
											{sale.car?.brand?.name}{" "}
											{sale.car?.model?.name}{" "}
											{sale.car?.year} г.в.{" "}
											{sale.car?.engine_volume}{" "}
											{sale.car?.transmission?.name}{" "}
											{sale.car?.vin}{" "}
											{currentStatusReport?.status_report?.reg_number ?? sale?.reg_number ?? ""}
										</Link>
									);
								})}
							</div>
						);
					})}
				</div>
			);
		}
	},
	{
		dataField: "bet",
		text: "Моя ставка",
		formatter: (cell, row) => {
			return (
				<div className="d-grid gap-5px">
					{row.lots.map((lot, index) => {
						return (
							<div
								className="min-height"
								key={`lot-bet-${index}`}
							>
								<Tooltip
									text={
										lot.lastUserBet?.is_leading
											? "Ваша ставка лидирует"
											: "Ваша ставка перебита"
									}
								>
									<div
										className={`${lot.lastUserBet?.sum &&
										(lot.lastUserBet?.is_leading
											? "last-bet-is-my"
											: "last-bet-is-not-my")
										} text-align-center d-gid gap-5px`}
									>
										<img
											src={`/img/icons/${lot.lastUserBet?.is_leading
												? "my-bet"
												: "another-bet"
											}-icon.svg`}
										/>
										{lot.lastUserBet?.sum
											?.toString()
											.replace(
												/(\d)(?=(\d{3})+$)/g,
												"$1 "
											)}
									</div>
								</Tooltip>
							</div>
						);
					})}
				</div>
			);
		}
	},
	{
		dataField: "blitz",
		text: "Блиц-цена",
		headerStyle: () => {
			return { width: "140px" };
		},
		formatter: (cell, row) => {
			return (
				<div className="d-grid gap-5px">
					{row.lots.map((lot, index) => {
						return (
							<div
								className="min-height"
								key={`lot-blitz-${index}`}
							>
								{lot.is_blitz_price_enabled &&
									lot.blitz_price > 0 &&
									lot.lastBet.sum <= lot.blitz_price &&
									formatValueWithSpaces(lot.blitz_price)}
							</div>
						);
					})}
				</div>
			);
		}
	}
];

export const getFinishedColumns = (cities) => {

	const getClassName = (status, lostBet) => {
		if (LOT_RED_STATUSES.includes(status) || lostBet) {
			return "lot-status red";
		}
		if (LOT_GREEN_STATUSES.includes(status)) {
			return "lot-status green";
		}
		if (LOT_GREY_STATUSES.includes(status)) {
			return "lot-status grey";
		}
		if (LOT_ORANGE_STATUSES.includes(status)) {
			return "lot-status orange";
		}
	};
	
	return [
		{
			dataField: "id",
			text: "№",
			headerStyle: () => {
				return { width: "112px" };
			},
			formatter: (cell, row) => (
				<div className="min-height">
					<div>{row?.id}</div>
					<div className="table__file-extra-text">
						{getTypeAuctionText(row?.auction_type)}
					</div>
				</div>
			)
		},
		{
			dataField: "start_at",
			text: "Даты",
			headerStyle: () => {
				return { width: "100px" };
			},
			formatter: (cell, row) => (
				<div className="d-flex flex-column min-height gap-5px justify-content-end align-items-end">
					{row.start_at && (
						<div>
							<div>
								{moment(row.start_at).format("DD.MM.YYYY")}
							</div>
							<div className="table__file-extra-text">
								{moment(row.start_at).format("HH:mm")}
							</div>
						</div>
					)}
					{row.finished_at && (
						<div className="d-flex">
							–
							<div>
								<div className="">
									{moment(row.finished_at).format(
										"DD.MM.YYYY"
									)}
								</div>
								<div className="table__file-extra-text">
									{moment(row.finished_at).format("HH:mm")}
								</div>
							</div>
						</div>
					)}
				</div>
			)
		},
		{
			dataField: "seller_title",
			text: "Продавец",
			headerStyle: () => {
				return { width: "160px" };
			},
			formatter: (cell, row) => {

				// TODO: ищем проданный лот с твоим выигрышем
				const lotSold = row.lots.find(lot =>
					lot.status?.code !== "lot_waiting_approval" &&
					lot.status?.code !== "lot_declined_seller" &&
					lot.status?.code !== "lot_no_bets" &&
					lot.status?.code !== "lot_active" &&
					lot.status?.code !== "auction_finished");

				return (
					<div
						className="min-height">{lotSold && lotSold?.winning_bet?.is_my ? row.sellerable?.display_name : row.seller_title}</div>
				);
			}
		},
		{
			dataField: "city",
			text: "Город",
			headerStyle: () => {
				return { width: "160px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							let relatedStatusReports = lot.relatedStatusReport;
							return (
								<div
									key={`lot-${index}`}
									className="d-grid gap-5px"
								>
									{lot.sales?.map((sale, index) => {
										let currentStatusReport = relatedStatusReports.find(x => x.sale_id === sale.id);
										let city = cities.find(x => x.id === currentStatusReport?.status_report?.city_id);
										return <div
											className="min-height">{city?.name ?? "-"}</div>;
									})}
								</div>
							);
						})}
					</div>
				);
			}
		},
		{
			dataField: "lot",
			text: "Лоты",
			headerStyle: () => {
				return { width: "260px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							let relatedStatusReports = lot.relatedStatusReport;
							return (
								<div
									key={`lot-${index}`}
									className="d-grid gap-5px"
								>
									{lot.sales?.map((sale, index) => {
										let currentStatusReport = relatedStatusReports.find(x => x.sale_id === sale.id);
										let urlStatusReport = currentStatusReport?.status_report?.id ? `&statusReportId=${currentStatusReport?.status_report?.id}` : "";
										if (!currentStatusReport?.status_report) {
											currentStatusReport = {
												status_report: {
													reg_number: sale.status_reports[0]?.reg_number
												}
											};
										}
										return (
											<Link
												to={`/car/${sale.id}?auctionId=${lot.auction_id}&lotId=${lot.id}&lotSale=true${urlStatusReport}`}
												key={`lot-sale-${index}`}
												className="table__file-blue-text min-height"
											>
												{sale.car?.brand?.name}{" "}
												{sale.car?.model?.name}{" "}
												{sale.car?.year} г.в.{" "}
												{sale.car?.engine_volume}{" "}
												{sale.car?.transmission?.name}{" "}
												{sale.car?.vin}{" "}
												{currentStatusReport?.status_report?.reg_number ?? sale?.reg_number ?? ""}
											</Link>
										);
									})}
								</div>
							);
						})}
					</div>
				);
			}
		},
		{
			dataField: "Status_lot",
			text: "Статус",
			headerStyle: () => {
				return { width: "110px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							const lostBet = !lot.lastBet?.is_my;

							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									<div
										className={`${getClassName(
											lot?.status?.code,
											lostBet
										)}`}
									>
										{lot.statusMessage}
									</div>
								</div>
							);
						})}
					</div>
				);
			}
		},
		{
			dataField: "last_status_change",
			text: "Изменен",
			headerStyle: () => {
				return { width: "140px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									{lot.last_status_change?.created_at &&
										formatDateWithTime(
											lot.last_status_change?.created_at
										)}
								</div>
							);
						})}
					</div>
				);
			}
		},
		{
			dataField: "starting_price",
			text: "Стартовая цена",
			headerStyle: () => {
				return { width: "140px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									{formatValueWithSpaces(lot.starting_price)}
								</div>
							);
						})}
					</div>
				);
			}
		},
		{
			dataField: "minimal_step",
			text: "Шаг торгов",
			headerStyle: () => {
				return { width: "140px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									{formatValueWithSpaces(lot.minimal_step)}
								</div>
							);
						})}
					</div>
				);
			}
		},
		{
			dataField: "minimal_price",
			text: "Минимальная цена",
			headerStyle: () => {
				return { width: "140px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									{formatValueWithSpaces(lot.minimal_price)}
								</div>
							);
						})}
					</div>
				);
			}
		},
		{
			dataField: "bet",
			text: "Моя ставка",
			headerStyle: () => {
				return { width: "100px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									{formatValueWithSpaces(
										lot.lastUserBet?.sum
									)}
								</div>
							);
						})}
					</div>
				);
			}
		},
		{
			dataField: "blitz",
			text: "Блиц-цена",
			headerStyle: () => {
				return { width: "100px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									className="min-height"
									key={`lot-blitz-${index}`}
								>
									{lot.is_blitz_price_enabled &&
										lot.blitz_price > 0 &&
										lot.lastBet.sum <= lot.blitz_price &&
										formatValueWithSpaces(lot.blitz_price)}
								</div>
							);
						})}
					</div>
				);
			}
		},
		{
			dataField: "max-bet",
			text: "Макс. ставка",
			headerStyle: () => {
				return { width: "110px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							if (
								lot.is_blitz_price_enabled &&
								lot.blitz_price > 0 &&
								lot.lastBet.sum <= lot.blitz_price
							)
								return;

							return (
								row.auction_type !== "buy_now" && (
									<div
										key={`lot-${index}`}
										className="min-height"
									>
										{formatValueWithSpaces(
											lot.lastBet?.sum
										)}
									</div>
								)
							);
						})}
					</div>
				);
			}
		},
		{
			dataField: "confirmed-bet",
			text: "Подтвержд.",
			headerStyle: () => {
				return { width: "100px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									{lot.winning_bet?.sum
										?.toString()
										.replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}
								</div>
							);
						})}
					</div>
				);
			}
		}
	];
};
